<template>
    <div :class="['rb-view',userId?'dialog':'']">
        <div>
            <div>
                <el-form :ref="search_form_name" :model="search_option" size="mini" inline label-width="80px">
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="订单日期:" prop="time_range">
                                <el-radio-group v-model="search_option.time_range" @change="handleSearchTap">
                                    <el-radio-button label="all">全部</el-radio-button>
                                    <el-radio-button label="today">今日</el-radio-button>
                                    <el-radio-button label="yesterday">昨天</el-radio-button>
                                    <el-radio-button label="7day">7天内</el-radio-button>
                                    <el-radio-button label="30day">30天内</el-radio-button>
                                    <el-radio-button label="this_month">今月</el-radio-button>
                                    <el-radio-button label="this_year">今年</el-radio-button>
                                </el-radio-group>
                                <span>
                                    <span style="margin-left:20px;margin-right:10px;">自定义:</span>
                                      <el-date-picker @change="handleSearchTap"
                                                      v-model="search_option.time_range"
                                                      type="daterange"
                                                      range-separator="至"
                                                      start-placeholder="开始日期"
                                                      end-placeholder="结束日期">
                                      </el-date-picker>
                                </span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <el-form-item label="状态:" prop="status">
                                <el-radio-group v-model="search_option.status" @change="handleSearchTap">
                                    <el-radio-button label="all">全部</el-radio-button>
                                    <el-radio-button label="cancel">已关闭</el-radio-button>
                                    <el-radio-button label="unpay">未支付</el-radio-button>
                                    <el-radio-button label="finish">已完成</el-radio-button>
                                </el-radio-group>
                            </el-form-item>

                            <el-form-item label="充值包" prop="recharge_type">
                                <el-radio-group v-model="search_option.recharge_type" @change="handleSearchTap">
                                    <el-radio-button label="all">全部</el-radio-button>
                                    <el-radio-button label="weekday">工作日</el-radio-button>
                                    <el-radio-button label="everyday">全日巅峰</el-radio-button>
                                    <el-radio-button label="upgrade">升级</el-radio-button>
                                </el-radio-group>
                            </el-form-item>

                            <el-form-item label="时长" prop="recharge_duration">
                                <el-radio-group v-model="search_option.recharge_duration" @change="handleSearchTap">
                                    <el-radio-button label="all">全部</el-radio-button>
                                    <el-radio-button label="month">包月</el-radio-button>
                                    <el-radio-button label="season">包季</el-radio-button>
                                    <el-radio-button label="year">包年</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <el-form-item label="订单号:" prop="id"><el-input clearable v-model="search_option.id"></el-input></el-form-item>
                            <el-form-item label="用户ID:" prop="user_id"><el-input :clearable="userId?false:true" :disabled="userId?true:false" v-model="search_option.user_id"></el-input></el-form-item>
                            <el-form-item label="昵称:" prop="nick_name"><el-input clearable v-model="search_option.nick_name"></el-input></el-form-item>
                            <el-form-item label="姓名:" prop="real_name"><el-input clearable v-model="search_option.real_name"></el-input></el-form-item>
                            <el-form-item label="登记姓名:" prop="contact_real_name"><el-input clearable v-model="search_option.contact_real_name"></el-input></el-form-item>
                            <el-form-item label="登记电话:" prop="contact_telephone"><el-input clearable v-model="search_option.contact_telephone"></el-input></el-form-item>
                            <el-form-item label="渠道单号:" prop="transaction_id"><el-input clearable v-model="search_option.transaction_id"></el-input></el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-button type="primary" icon="el-icon-search" size="mini" style="" @click="handleSearchTap">查询</el-button>
                            <el-button icon="el-icon-delete" size="mini" @click="handleSearchClear(search_form_name)">清空</el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </div>

            <div style="margin-top: 16px;">
                <el-table :data="page_list" size="mini" border>
                    <el-table-column label="ID" prop="id" width="144" fixed="left"></el-table-column>
                    <el-table-column label="创建时间" prop="created_at" show-overflow-tooltip :formatter="elTimeStampFormat" width="136"></el-table-column>
                    <el-table-column label="会员ID" prop="user_id" width="144" show-overflow-tooltip></el-table-column>
                    <el-table-column label="头像" prop="avatar" width="49">
                        <template #default="scope">
                            <el-avatar :src="scope.row.avatar" size="small"></el-avatar>
                        </template>
                    </el-table-column>
                    <el-table-column label="昵称" prop="nick_name" min-width="72" show-overflow-tooltip></el-table-column>
                    <el-table-column label="真实姓名" prop="real_name" show-overflow-tooltip width="72"></el-table-column>
                    <el-table-column label="登记姓名" prop="contact_real_name" show-overflow-tooltip width="72"></el-table-column>
                    <el-table-column label="登记电话" prop="contact_telephone" show-overflow-tooltip width="96"></el-table-column>
                    <el-table-column label="登记生日" prop="contact_birthday" :formatter="elTimeStampFormatDate" width="88"></el-table-column>
                    <el-table-column label="状态" prop="status" width="64" align="center">
                        <template #default="scope">
                            <span :style="{color:params_map['order_recharge_status']?params_map['order_recharge_status'][scope.row.status].color:''}">{{params_map['order_recharge_status']?params_map['order_recharge_status'][scope.row.status].value:''}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="类别" prop="recharge_act" width="48" align="center">
                        <template #default="scope">
                            <span :style="{color:params_map['recharge_act']?params_map['recharge_act'][scope.row.recharge_act].color:''}">{{params_map['recharge_act']?params_map['recharge_act'][scope.row.recharge_act].value:''}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="充值包" prop="recharge_type" show-overflow-tooltip width="120">
                        <template #default="scope">
                            <span :style="{color:(params_map['recharge_type']&&params_map['recharge_type'][scope.row.recharge_type])?params_map['recharge_type'][scope.row.recharge_type].color:''}">{{(params_map['recharge_type'] && params_map['recharge_type'][scope.row.recharge_type]) ? params_map['recharge_type'][scope.row.recharge_type].value:''}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="时长" prop="recharge_duration" width="48" align="center">
                        <template #default="scope">
                            <span :style="{color:params_map['recharge_duration']&&params_map['recharge_duration'][scope.row.recharge_duration]?params_map['recharge_duration'][scope.row.recharge_duration].color:''}">{{(params_map['recharge_duration'] && params_map['recharge_duration'][scope.row.recharge_duration]) ? params_map['recharge_duration'][scope.row.recharge_duration].value:''}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="总价" prop="total_price" width="56" align="right">
                        <template #default="scope">
                            <span style="font-weight:600;">{{scope.row.total_price}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="优惠" prop="" align="right" width="56"></el-table-column>
                    <el-table-column label="应付" prop="total_amount" align="right" width="56">
                        <template #default="scope">
                            <span style="font-weight:600;">{{scope.row.total_amount}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="实付" prop="pay_amount" align="right" width="56">
                        <template #default="scope">
                            <span style="font-weight:600;color:#409EFF">{{scope.row.total_payment}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="成交时间" prop="pay_done_at" width="136" :formatter="elTimeStampFormat"></el-table-column>
                    <el-table-column label="渠道单号" prop="transaction_id" width="80" show-overflow-tooltip></el-table-column>
                    <el-table-column label="退款" width="64" show-overflow-tooltip>
                        <template #default="scope">
                            <span style="">{{(params_map['refund_status'] && params_map['refund_status'][scope.row.refund_status]) ? params_map['refund_status'][scope.row.refund_status].value:''}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="实退" width="56" show-overflow-tooltip align="right" prop="refund_amount">
                        <template #default="scope">
                            <span style="font-weight:600;color:#F56C6C;">{{scope.row.refund_amount}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="退款时间" width="136" show-overflow-tooltip prop="refund_at" :formatter="elTimeStampFormat"></el-table-column>
                    <el-table-column label="退款人" width="80" show-overflow-tooltip prop="sys_real_name"></el-table-column>
                    <el-table-column label="操作" width="96" fixed="right" align="center">
                        <template #default="scope">
                            <el-button v-if="profile.is_admin == true && scope.row.can_refund === true" size="mini" type="text" @click="onOrderCloseTap(scope.row)">退款</el-button>
                            <el-button type="text" @click="onEditTap(scope.row)" size="mini">编辑</el-button>
                      </template>
                    </el-table-column>
                </el-table>
                <div style="margin-top:20px;margin-bottom: 0px; text-align:right;">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="page_option.page"
                            :page-sizes="[5,10, 20, 30, 50,100,200]"
                            :page-size="page_option.size"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="page_option.total"
                    ></el-pagination>
                </div>
            </div>
        </div>
      <el-dialog
          v-model="dialogFormVisible"
          :title="dialogFormTitle"
          @close="onTellDialogClose"
          width="600px"
          :close-on-click-modal="false"
          :fullscreen="false"
          :destroy-on-close="true">
        <order-recharge-detail
            :action="dialogFormAction"
            :row-id="dialogFormRowId"
            :row-name="dialogFormRowName"
            :row-phone="dialogFormRowPhone"
            :row-birthday="dialogFormRowBirthday"
            @onTellDialogClose="onTellDialogClose"></order-recharge-detail>
      </el-dialog>

    </div>
</template>

<script>
    import OrderRechargeDetail from "./order-recharge-detail"
    import {closeWithRefund, getList} from "@/api/hado/order-recharge";
    import {getParams} from "@/api/hado/param";
    import {formatTimestamp, formatTimestampData} from "@/utils/time";
    import {mapGetters} from "vuex";
    const param_list = ['order_recharge_status','recharge_act','recharge_type','recharge_duration','refund_status']
    export default {
        name: "order-recharge-list",
        components:{OrderRechargeDetail},
        props:{
           userId:{
               type:String,
               default:''
           }
        },
         data(){
            return{
               dialogFormTitle:'',
               dialogFormVisible:false,
               dialogFormRowId:0,
               dialogFormRowName:'',
               dialogFormRowPhone:0,
               dialogFormRowBirthday:0,
               dialogFormAction:'',//create view edit

               loading:false,
               search_form_name:'order_recharge_search',
               page_option:{page:1,size:30,total:0},
               search_option:{id:'',status:'all',recharge_act:'all',recharge_type:'all',recharge_duration:'all',time_range:'all'},
               page_list:[],
               params:{order_recharge_status:[],recharge_act:[]},
               params_map_pure:{},
               params_map:{order_recharge_status:{},recharge_act:{}},
            }
        },
        created(){
            //如果是dialog状态 即有userId状态的 就给个初始变量
            this.search_option.user_id = this.userId
            this.initPage()
            // console.log('this.$parent',this.parent)
        },
        computed:{
            ...mapGetters([
                'profile'
            ])
        },
        methods:{
            initPage(){
                this.initParams().then(()=>{
                    this.getPageList({page:1,size:this.page_option.size},Object.assign({},this.search_option))
                })
            },
            reloadPage() {
                this.getPageList(this.page_option, this.search_option)
            },
            initParams(){
                return new Promise((resolve,reject)=>{
                    getParams(param_list.join(','),'en').then(resp=>{
                        this.params = resp.data;

                        let tmp_params_map = {}
                        let tmp_params_map_pure = {}
                        let tmp_params_map_reverse = {}

                        //这里再这里一下每组参数做成kv方便显示
                        let p_keys = Object.keys(this.params)
                        p_keys.forEach(pl=>{
                            console.log('pl:',pl)
                            let tmp_map = {}
                            let tmp_map_pure = {}
                            let tmp_map_reverse = {}
                            this.params[pl].forEach(pm=>{
                                // tmp_map[pm.key]=pm.value
                                tmp_map_pure[pm.key]=pm.value
                                tmp_map[pm.key]={key:pm.key,value:pm.value,color:pm.color}

                                //再调转处理一下 导入Excel参数解析用
                                tmp_map_reverse[pm.value]=pm.key
                            })
                            tmp_params_map[pl] = tmp_map
                            tmp_params_map_pure[pl] = tmp_map_pure
                            tmp_params_map_reverse[pl] = tmp_map_reverse
                        })

                        this.params_map = tmp_params_map
                        this.params_map_pure = tmp_params_map_pure
                        this.params_map_reverse = tmp_params_map_reverse
                        // console.log('params_map:',this.params_map)
                        // console.log('params_map_reverse:',this.params_map_reverse)
                        resolve(resp)
                    }).catch((err)=>{
                        if(err){
                            console.error(err)
                        }
                        reject(err)
                    })
                })
            },
            getPageList(pageOpt,searchOpt){
                this.loading = true;
                getList(pageOpt.page,pageOpt.size,searchOpt).then(resp=>{
                    this.loading=false;
                    this.page_list = resp.data.records;
                    this.page_option.page = pageOpt.page;
                    this.page_option.total = resp.data.total;
                    this.page_option.size = pageOpt.size;
                }).catch(()=>{
                    this.loading = false;
                })
            },
            handleSearchTap(){
                let searchOpt = Object.assign({},this.search_option)
                this.getPageList({page:1,size:this.page_option.size},searchOpt)
            },
            handleSearchClear(formName){
                this.$refs[formName].resetFields();
                this.initPage()
            },
            handleSizeChange(pageSize){
                this.getPageList({page:1,size:pageSize},this.search_option);
            },
            handleCurrentChange(pageIndex){
                this.getPageList({page:pageIndex,size:this.page_option.size},this.search_option)
            },
            onRowViewTap(row){
                console.log('onRowViewTap:',row)
            },
            elTimeStampFormat(row,column,val){
                return formatTimestamp(val)
            },
            elTimeStampFormatDate(row,column,val){
                return formatTimestampData(val)
            },
            onOrderCloseTap(row){
                this.$confirm(`取消订单将同时退款 ￥${row.total_payment},请仔细操作`,'取消订单确认!!',{
                    type:'warning',
                    confirmButtonText:'确定取消',
                    cancelButtonText:'再考虑',
                }).then(()=>{
                    closeWithRefund(row.id).then(()=>{this.reloadPage()}).catch(()=>{this.reloadPage()})
                })
            },

            onEditTap(row) {
              console.log('onEditTap:', row)
              this.dialogFormAction = 'edit'
              this.dialogFormRowId = row.id
              this.dialogFormVisible = true
              this.dialogFormRowName = row.contact_real_name
              this.dialogFormRowPhone = row.contact_telephone
              this.dialogFormRowBirthday = row.contact_birthday
            },
            onTellDialogClose() {
              this.dialogFormVisible = false;
              this.dialogFormRowId = 0;
              this.reloadPage()
            },

          }
    }
</script>

<style scoped>

</style>
