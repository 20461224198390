/**
Created by Roy on 2021/10/10 14:52
*/
<template>
  <div>
    <el-form ref="form_data"
             :model="form_data"
             :rules="rules_form_data">
      <el-form-item label="姓名:" prop="contact_real_name">
        <el-input v-model="form_data.contact_real_name" style="width: 100%"></el-input>
      </el-form-item>
      <el-form-item label="电话:" prop="contact_telephone">
        <el-input v-model="form_data.contact_telephone" style="width: 100%"></el-input>
      </el-form-item>
      <el-form-item label="年龄:" prop="contact_birthday">
        <el-date-picker v-model="form_data.contact_birthday" type="datetime" placeholder="选择日期时间"
                        format="YYYY-MM-DD HH:00:00"></el-date-picker>
      </el-form-item>
    </el-form>

    <div style="text-align:right;">
      <el-button v-if="action!=='view'" type="primary" @click="submitForm('form_data')" size="mini">提交编辑</el-button>
      <el-button @click="onCloseDialog" size="mini">关闭</el-button>
    </div>
  </div>
</template>

<script>
import {updateContact} from '@/api/hado/order-recharge'

export default {
  name: "order-recharge-detail",
  props: {
    action: {
      value: String,
      default: 'add'
    },
    rowId: {
      value: String
    },
    rowName: {
      value: String
    },
    rowPhone: {
      value: String
    },
    rowBirthday: {
      value: Number
    },
  },
  data() {
    return {
      form_name: 'order_recharge_detail',

      form_data: {
        id: 0,
        contact_real_name: '',
        contact_telephone: '',
        contact_birthday: 0
      },
      rules_form_data: {
        contact_real_name: [
          {required: true, message: '请正确输入姓名', trigger: 'blur', min: 1, max: 5,},
        ],
        contact_telephone: [
          {required: true, min: 11, message: '手机号码长度为11', trigger: 'blur'},
        ],
        contact_birthday: [
          {required: true, message: '请选择年龄', trigger: 'blur'},
        ]
      }
    }
  },
  computed:{
    formDisabled(){
      return this.action === 'view' || !this.action;
    }
  },
  created() {
    this.initPage()
  },
  methods: {
    initPage() {
      //传递参数赋值
      this.form_data.id = this.rowId
      this.form_data.contact_real_name = this.rowName
      this.form_data.contact_telephone = this.rowPhone
      this.form_data.contact_birthday = this.rowBirthday
      console.log(this.form_data)
    },
    //提交
    submitForm(formName) {
      // 把生日重新换成时间戳
      this.form_data.contact_birthday = new Date(this.form_data.contact_birthday).getTime()
      //提交上去
      this.$refs[formName].validate((valid) => {
        console.log(this.form_data)
        if (valid) {
          updateContact(this.form_data).then(() => {
            this.$emit('onTellDialogClose')
          })
        }
      })
    },
    onCloseDialog() {
      this.$emit("onTellDialogClose")
    },
  }
}
</script>

<style scoped>

</style>
